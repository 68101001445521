/* Slide in animation */
.animateTileL {
  animation-duration: 1s;
  animation-name: animate-tile-l;
}

.animateTileR {
  animation-duration: 1s;
  animation-name: animate-tile-r;
}

@keyframes animate-tile-l {
  0% {
    opacity: 0;
    transform: translateX(-20vw);
  }
}
@keyframes animate-tile-r {
  0% {
    opacity: 0;
    transform: translateX(20vw);
  }
}

@media (not (min-width: 62em)) or (prefers-reduced-motion) {
  @keyframes animate-tile-l {
    0% {
      opacity: 0;
    }
  }
  @keyframes animate-tile-r {
    0% {
      opacity: 0;
    }
  }
}

/* Show buttons on Hover */
@media screen and (min-width: 62em) {
  .button {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  .card:hover .button {
    opacity: 1;
  }
}
